import { Link } from 'gatsby';
import React, { useState } from "react";
import Transition from "./transition.js";
import LogoImg from "../images/logo.png"

function Header(){
  const [isOpen, setIsOpen] = useState(false);

  return(
  <header>
    <div className="relative bg-white z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1">
            <Link to="/" className="flex">
              <img src={LogoImg} className="h-12 w-auto sm:h-12" alt="Tokensack"></img>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button type="button" onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
          {/*<Link to="/">
            <a href="" className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
              Blog
            </a>
            </Link>
            <a href="/impact" className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
              Impact
            </a>*/}


          </nav>

          <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
          <span class="inline-flex rounded-md shadow">
            <a href="./#tokens" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-700 transition duration-150 ease-in-out">
              Tokens
            </a>
          </span>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
<div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
  <div className="rounded-lg shadow-lg">
    <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
      <div className="pt-5 pb-6 px-5  space-y-6">
        <div className="flex items-center justify-between">
          <div>
            <img className="h-8 w-auto" src={LogoImg} alt="Tokensack" />
          </div>
          <div className="-mr-2">
            <button type="button"  onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <nav className="grid grid-cols-1 gap-7">
            <a href="./#tokens" className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-900 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-6 w-6" fill="currentColor"><path className="heroicon-ui" d="M17 7h2.25c.97 0 1.75.78 1.75 1.75v10.5c0 .97-.78 1.75-1.75 1.75H8.75C7.78 21 7 20.22 7 19.25V17H4.75C3.78 17 3 16.22 3 15.25V4.75C3 3.78 3.78 3 4.75 3h10.5c.97 0 1.75.78 1.75 1.75V7zm-2 0V5H5v10h2V8.75C7 7.78 7.78 7 8.75 7H15zM9 9v10h10V9H9z"/></svg>
              </div>
              <div className="text-base leading-6 font-medium text-gray-900">
                Tokens
              </div>
            </a>

          </nav>
        </div>
      </div>
      {/*<div className="py-6 px-5 space-y-6">
        <div className="space-y-6">
          <span className="w-full flex rounded-md shadow-sm">
            <a href="./#subscribe" className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150">
              Subscribe
            </a>
          </span>
        </div>
      </div>*/}
    </div>
  </div>
</div>

</Transition>


    </div>

</header>

  );
}

export default Header;
